import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '../core/service/auth.service';

@Component({
    selector: 'app-app-landing',
    templateUrl: './app-landing.component.html',
    styleUrls: ['./app-landing.component.scss']
})
export class AppLandingComponent implements OnInit {

    constructor(
        private titleService: Title,
        private router: Router,
        public authService: AuthService
        ) {
        this.titleService.setTitle('RxValidator Landing');
    }

    ngOnInit() {
        if(this.authService.currentUserValue != null && this.authService.currentUserValue.email != null) {
            this.router.navigate(['/dashboard/main']);
        }
    }

    getStarted() {
        this.router.navigate(['/authentication/signup']);
    }

}
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import { StripeService } from 'ngx-stripe';
import { AuthService } from '../core/service/auth.service';
import { PaymentsService } from '../payments.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {
  constructor(
    private payments: PaymentsService,
    private stripeService: StripeService,
    private auth: AuthService
  ) {}
  // Prod: price_1NKmdLFxCPzbfwATBgvohM1b
  // Test: price_1MdQVbFxCPzbfwATVt0stR2n
  // Also exists in signup.component
  public priceId = "price_1NKmdLFxCPzbfwATBgvohM1b"
  public session_id = ""
  public checkout() {
    this.payments.checkout(this.priceId, this.auth.currentUserValue.uid, this.auth.currentUserValue.email).subscribe(
      (v) => {
        this.session_id = v['id'];
      }
    )
  }
}